import {PatientFile, UserFileType} from "../dashboard";
import {
    Button,
    Checkbox,
    CheckboxProps,
    Dropdown,
    DropdownItemProps,
    DropdownProps,
    Form,
    Icon,
    Table
} from "semantic-ui-react";
import React, {FormEvent, useState} from "react";
import styles from "./index.module.css";
import agent from "../../agent";
import {Utils} from "../../helpers/Utils";
import {getRoleEnableFileUpload} from "../../utils/common";
import {hasRole, UserRole} from "../../helpers/UserRole";
import UserFileDeleteModal from "./UserFileDeleteModal";

interface Props {
    filesList: PatientFile[];
    userFileTypes: UserFileType[];
    onAddClick?: Function;
}

interface State {
    sortBy: "date" | "type" | "name";
    sortDirection: "ascending" | "descending"
    sortedList: PatientFile[];
    showUploadDialog: boolean;
    showDeleteDialog: boolean;
    filesList: PatientFile[];
    selectedFiles: PatientFile[];
}

class FileTable extends React.PureComponent<Props, State> {
    state: State = {
        sortedList: [],
        sortDirection: "descending",
        sortBy: "date",
        showUploadDialog: false,
        showDeleteDialog: false,
        filesList: this.props.filesList,
        selectedFiles: []
    };

    async componentDidMount() {
        await this.load();
    };

    private async load() {
        var list = this.state.filesList.sort((a: PatientFile, b: PatientFile) => {
            if (this.state.sortBy == "date") {
                if (this.state.sortDirection === "descending")
                    return (a.uploadDate < b.uploadDate) ? 1 : ((b.uploadDate < a.uploadDate) ? -1 : 0);
                else
                    return (b.uploadDate < a.uploadDate) ? 1 : ((a.uploadDate < b.uploadDate) ? -1 : 0);
            } else if (this.state.sortBy == "name") {
                if (this.state.sortDirection === "descending")
                    return b.filename.localeCompare(a.filename);
                else
                    return a.filename.localeCompare(b.filename);
            } else if (this.state.sortBy == "type") {
                let sa = "";
                let sb = "";
                if (a.metadata?.fileTypeId != null) {
                    sa = this.props.userFileTypes.find((x) => x._id == a.metadata?.fileTypeId)?.name ?? "";
                }
                if (b.metadata?.fileTypeId != null) {
                    sb = this.props.userFileTypes.find((x) => x._id == b.metadata?.fileTypeId)?.name ?? "";
                }

                if (this.state.sortDirection === "descending")
                    return sb.localeCompare(sa);
                else
                    return sa.localeCompare(sb);
            } else
                return 0;
        });
        console.log("load, sordBy = " + this.state.sortBy + ", sordDirection = " + this.state.sortDirection);
        await this.setState({
            sortedList: list
        });
    }

    render() {
        return (
            <div className={styles.container}>
                {this.state.showDeleteDialog &&
                <UserFileDeleteModal
                    files={this.state.selectedFiles}
                    onClose={async (success) => {
                        if (success) {
                            for (const file of this.state.selectedFiles) {
                                const index = this.state.filesList.indexOf(file!, 0);
                                if (index > -1) {
                                    this.state.filesList.splice(index, 1);
                                }
                            }
                            this.setState({
                                filesList: this.state.filesList,
                                selectedFiles: []
                            })
                            await this.load();
                        }
                        this.setState({
                            showDeleteDialog: false
                        })
                    }}
                />}


                <Table celled selectable striped verticalAlign={'middle'}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width={1}/>
                            <Table.HeaderCell
                                sorted={this.state.sortBy == "name" ? this.state.sortDirection : undefined}
                                onClick={() => this.sortColumn('name')}>
                                Файл</Table.HeaderCell>
                            <Table.HeaderCell
                                sorted={this.state.sortBy == "date" ? this.state.sortDirection : undefined}
                                onClick={() => this.sortColumn('date')}>
                                Дата и время получения</Table.HeaderCell>
                            <Table.HeaderCell
                                sorted={this.state.sortBy == "type" ? this.state.sortDirection : undefined}
                                onClick={() => this.sortColumn('type')}>
                                Тип файла</Table.HeaderCell>
                            <Table.HeaderCell>
                                {getRoleEnableFileUpload() &&
                                window.config?.moniki == true &&
                                <Button
                                    fluid
                                    color={'blue'}
                                    type={'button'}
                                    content={'Добавить'}
                                    disabled={hasRole(UserRole.OBSERVER)}
                                    onClick={async () => {
                                        if (this.props.onAddClick != null) {
                                            this.props.onAddClick();
                                        }
                                    }}
                                />}
                            </Table.HeaderCell>
                            {window.config?.moniki && hasRole(UserRole.ADMIN) &&
                            <Table.HeaderCell>
                                {(this.state.selectedFiles.length > 0) && <Button
                                    fluid
                                    icon
                                    style={{backgroundColor: '#FFEEEE'}}
                                    type={'button'}
                                    onClick={async () => {
                                        this.setState({
                                            showDeleteDialog : true
                                        })
                                    }}
                                >
                                    <Icon name={"trash"} style={{color: '#FF3333'}}/>
                                </Button>}
                            </Table.HeaderCell>}

                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {
                            this.state.sortedList && this.state.sortedList.map((el, index) =>
                                (
                                    <Table.Row style={{cursor: "pointer"}} key={`key-${index}-${el._id}`}
                                               positive={!!el.metadata?.isFromAdmin}>
                                        <Table.Cell>
                                            <Button icon size="mini"
                                                    disabled={hasRole(UserRole.OBSERVER)}
                                                    onClick={async () => {
                                                await Utils.openFile({fileId: el._id});
                                            }}>
                                                <Icon name="file outline" color={"blue"}/>
                                            </Button>
                                        </Table.Cell>
                                        <Table.Cell floated={'left'}>
                                            {el.filename}
                                        </Table.Cell>

                                        <Table.Cell floated={'left'}>
                                            {el.uploadDate && new Date(el.uploadDate).toLocaleString("RU")}
                                        </Table.Cell>

                                        <Table.Cell floated={'left'}>
                                            <Dropdown
                                                selection
                                                search={true}
                                                clearable={true}
                                                value={el.metadata?.fileTypeId}
                                                disabled={hasRole(UserRole.OBSERVER)}
                                                onChange={(event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
                                                    var type = data.value as String | undefined;
                                                    if (type != "" && type != undefined) {
                                                        el.metadata!.fileTypeId = type?.toString();
                                                        agent.FileProvider.setType(el._id, type?.toString())
                                                    } else {
                                                        el.metadata!.fileTypeId = undefined;
                                                        agent.FileProvider.setType(el._id, "")
                                                        data.text = undefined;
                                                        // setSortedList(filesList.sort((a: PatientFile, b: PatientFile) =>
                                                        //     (a.uploadDate < b.uploadDate) ? 1 : ((b.uploadDate < a.uploadDate) ? -1 : 0)));
                                                    }
                                                }}
                                                options={this.props.userFileTypes.map((type) => ({
                                                    key: type._id,
                                                    text: type.name,
                                                    value: type._id,
                                                } as DropdownItemProps))}
                                            />

                                        </Table.Cell>

                                        <Table.Cell floated={'right'}>
                                            <Button
                                                fluid
                                                color={'green'}
                                                type={'submit'}
                                                content={'Скачать'}
                                                disabled={hasRole(UserRole.OBSERVER)}
                                                onClick={async () => {
                                                    await Utils.openFile({
                                                        file: el,
                                                        download: true
                                                    })
                                                }}
                                            />
                                        </Table.Cell>

                                        {window.config?.moniki && hasRole(UserRole.ADMIN) &&
                                        <Table.Cell floated={'right'}>
                                            <Checkbox
                                                checked={this.state.selectedFiles.indexOf(el) >= 0}
                                                onChange={(e: FormEvent<HTMLInputElement>, data: CheckboxProps) => {
                                                    if (data.checked === true) {
                                                        if (this.state.selectedFiles.indexOf(el) < 0) {
                                                            let newFileList = [...this.state.selectedFiles];
                                                            newFileList.push(el);
                                                            this.setState({
                                                                selectedFiles: newFileList
                                                            });
                                                        } else if (this.state.selectedFiles.indexOf(el) >= 0) {
                                                            let newFileList = [...this.state.selectedFiles];
                                                            newFileList.splice(this.state.selectedFiles.indexOf(el), 1);
                                                            this.setState({
                                                                selectedFiles: newFileList
                                                            });
                                                        }
                                                    }
                                                }
                                                }/>

                                        </Table.Cell>
                                        }

                                    </Table.Row>
                                )
                            )
                        }
                    </Table.Body>
                </Table>
            </div>);
    }

    private async sortColumn(name: "date" | "type" | "name") {
        if (name == this.state.sortBy) {
            await this.setState({
                sortDirection: (this.state.sortDirection == "ascending" ? "descending" : "ascending")
            });
        } else {
            await this.setState({
                sortBy: name,
                sortDirection: "ascending"
            })
        }
        await this.load();
        this.forceUpdate();
    }
}

export default FileTable;