import React, {ChangeEvent} from "react";
import agent from "../../agent";
import styles from "./index.module.css";
import {Button, Form, Grid, Loader, Menu, Message} from "semantic-ui-react";
import ChangeAdminPasswordModal from "./ChangeAdminPasswordModal";
import {getTZ} from "../../utils/common";
import BackupConfig from "./BackupConfig";
import {Redirect} from "react-router-dom";
import {hasRole, UserRole} from "../../helpers/UserRole";

export interface ConfigPanelOptions {
    mailHost: string,
    mailPort: number,
    mailUser: string,
    mailPassword: string,
    labelFrom: string,
    adminMail: string,
    vomode?: number,
    backupEnabled?: boolean,
    autoBackupSettings?: AutoBackupSettingsConfigModel,
    // SivovaAlex-20240517-ActivitySettingsRollback
    // activitySettings?: ActivitySettingsConfigModel
}

export interface AutoBackupSettingsConfigModel {
    enabled: boolean,
    hour: number,
    minute: number
}
// SivovaAlex-20240517-ActivitySettingsRollback
// export interface ActivitySettingsConfigModel {
//     withoutAssignmentDays: number,
//     inactiveDays: number,
//     passDays: number,
// }

interface Props {
}

interface State {
    mailHost: string,
    mailPort: number,
    mailUser: string,
    mailPassword: string,
    labelFrom: string,
    adminMail: string,
    loading: boolean,
    loadingCheck: boolean,
    transporterStatus: string,
    transporterError?: string,
    showChangeAdminPassword: boolean,
    backupEnabled: boolean,
    page: 'config' | 'backup' | 'observer',
}

interface ConfigResponse {
    success: boolean,
    error: string | undefined
}

class ConfigPanel extends React.PureComponent<Props, State> {
    state: State = {
        mailHost: '',
        mailPort: 0,
        mailUser: '',
        mailPassword: '',
        labelFrom: '',
        adminMail: '',
        loading: false,
        loadingCheck: false,
        transporterStatus: '',
        showChangeAdminPassword: false,
        page: 'config',
        backupEnabled: false
    }

    async componentDidMount() {
        await this.getConfig();
    };

    getConfig = async (): Promise<void> => {
        this.setState({ loading: true });

        const config: ConfigPanelOptions = await agent.ConfigProvider.get();

        if (config) {
            this.setState({
                mailHost: config.mailHost,
                mailPort: config.mailPort,
                mailPassword: config.mailPassword,
                mailUser: config.mailUser,
                adminMail: config.adminMail,
                labelFrom: config.labelFrom,
                transporterStatus: '',
                transporterError: undefined,
                loading: false,
                backupEnabled: config.backupEnabled!!
            })
        } else {
            this.setState({ loading: false });
        }
    };

    checkTransporter = async (): Promise<void> => {
        this.setState({ loadingCheck: true });

        const response: ConfigResponse = await agent.ConfigProvider.checkTransport();

        let status: string;

        if (response && response.success) {
            status = 'ok';
        } else {
            status = 'error'
        }

        this.setState({ transporterStatus: status, loadingCheck: false, transporterError: response.error });
    };

    onEdit = async (): Promise<void> => {
        this.setState({ loading: true });

        await agent.ConfigProvider.edit({
            mailHost: this.state.mailHost,
            mailPort: this.state.mailPort,
            mailUser: this.state.mailUser,
            mailPassword: this.state.mailPassword,
            labelFrom: this.state.labelFrom,
            adminMail: this.state.adminMail,
        });

        await this.getConfig();
    };

    getCheckButton = () => {
        switch (this.state.transporterStatus) {
            case "ok": {
                return (
                    <Button
                        fluid
                        labelPosition="right"
                        loading={this.state.loadingCheck}
                        className={styles.checkButton}
                        icon={'check'}
                        content={'Успешно'}
                        color={'green'}
                        onClick={this.checkTransporter}
                    />
                )
            }
            case "error": {
                return (
                    <Button
                        fluid
                        labelPosition="right"
                        loading={this.state.loadingCheck}
                        className={styles.checkButton}
                        icon={'exclamation'}
                        content={'Соединение отсутствует'}
                        color={'red'}
                        onClick={this.checkTransporter}
                    />
                )
            }
            default: {
                return (
                    <Button
                        fluid
                        loading={this.state.loadingCheck}
                        className={styles.checkButton}
                        content={'Проверить соединение'}
                        disabled={hasRole(UserRole.OBSERVER)}
                        color={'teal'}
                        onClick={this.checkTransporter}
                    />
                )
            }
        }
    }

    render() {
        const header: string = `Настройки системы`;
        return (
            <div className={styles.configContent}>
                <Menu tabular>
                    <Menu.Item
                        className={styles.item}
                        content='Настройки системы'
                        active={this.state.page === 'config'}
                        onClick={() => {
                            this.setState({ page: 'config' });
                        }}
                    />
                    {this.state.backupEnabled &&
                        <Menu.Item
                            content='Резервное копирование'
                            active={this.state.page === 'backup'}
                            onClick={() => {
                                this.setState({ page: 'backup' });
                            }}
                        />}
                    {hasRole(UserRole.ADMIN) &&
                        <Menu.Item
                            content='Наблюдатель'
                            active={this.state.page === 'observer'}
                            onClick={() => {
                                this.setState({page: 'observer'});
                            }}
                        />
                    }
                </Menu>

                {this.state.page == 'config' &&
                    <div>
                        {
                            this.state.loading ?
                                <Loader active />
                                :
                                <Form>
                                    <Grid>
                                        <Grid.Column width={4}>
                                            <Form.Input label={'Электронная почта администратора'}
                                                value={this.state.adminMail}
                                                disabled={hasRole(UserRole.OBSERVER)}
                                                onChange={(e: ChangeEvent<HTMLInputElement>) => this.setState({ adminMail: e.target.value })}
                                            />
                                            <Button
                                                fluid
                                                content={'Сменить пароль администратора'}
                                                disabled={hasRole(UserRole.OBSERVER)}
                                                color={'violet'}
                                                onClick={((event, data) => {
                                                    this.setState({
                                                        showChangeAdminPassword: true
                                                    });
                                                })}
                                            />
                                            <Message>
                                                <Message.Header>Временная зона</Message.Header>
                                                {getTZ()}
                                            </Message>
                                            <Message>
                                                <Message.Header>Версия</Message.Header>
                                                {window.config?.version ?? "не определена"}
                                            </Message>
                                        </Grid.Column>

                                        <Grid.Column width={4}>
                                            <Form.Input label={'SMTP host'}
                                                value={this.state.mailHost}
                                                disabled={hasRole(UserRole.OBSERVER)}
                                                onChange={(e: ChangeEvent<HTMLInputElement>) => this.setState({ mailHost: e.target.value })}
                                            />
                                            <Form.Input label={'SMTP port'}
                                                value={this.state.mailPort}
                                                disabled={hasRole(UserRole.OBSERVER)}
                                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                    const port: number = parseInt(e.target.value);

                                                    if (!isNaN(port)) {
                                                        this.setState({ mailPort: port });
                                                    }
                                                }}
                                            />
                                            <Form.Input label={'Электронная почта отправителя'}
                                                value={this.state.mailUser}
                                                disabled={hasRole(UserRole.OBSERVER)}
                                                onChange={(e: ChangeEvent<HTMLInputElement>) => this.setState({ mailUser: e.target.value })}
                                            />
                                            <Form.Input label={'Пароль отправителя'}
                                                type={'password'}
                                                value={this.state.mailPassword}
                                                disabled={hasRole(UserRole.OBSERVER)}
                                                onChange={(e: ChangeEvent<HTMLInputElement>) => this.setState({ mailPassword: e.target.value })}
                                            />
                                            <Form.Input label={'Имя отправителя'}
                                                value={this.state.labelFrom}
                                                disabled={hasRole(UserRole.OBSERVER)}
                                                onChange={(e: ChangeEvent<HTMLInputElement>) => this.setState({ labelFrom: e.target.value })}
                                            />

                                            <div className={styles.checkButtonContainer}>
                                                {
                                                    this.getCheckButton()
                                                }
                                            </div>

                                            <p style={{ color: "red" }}>{this.state.transporterError}</p>
                                        </Grid.Column>

                                        <Grid.Column width={4}>

                                            <div className={styles.saveButtonContainer}>
                                                <Button
                                                    className={styles.saveButton}
                                                    color={'green'}
                                                    type={'submit'}
                                                    content={'Сохранить'}
                                                    disabled={hasRole(UserRole.OBSERVER)}
                                                    onClick={this.onEdit}
                                                />
                                            </div>

                                        </Grid.Column>
                                    </Grid>
                                </Form>
                        }
                        {!!this.state.showChangeAdminPassword &&
                            <ChangeAdminPasswordModal
                                onClose={async (success) => {
                                    this.setState({
                                        showChangeAdminPassword: false
                                    })
                                }}
                            />}

                    </div>}
                {this.state.backupEnabled && this.state.page == 'backup' && <BackupConfig />}

                {this.state.page == 'observer' &&
                <div>
                    {
                        this.state.loading ?
                            <Loader active />
                            :
                            <Redirect to={'/dashboard/observers'}/>
                            // <ObserversList/>
                    }
                </div>
                }
            </div>
        )
    }
}

export default ConfigPanel;